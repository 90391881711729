import HeroImage from 'images/404-page/hero';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { CommunityLink, GatsbyLink } from 'components/Links';
import { HeroContainer, Section, SectionImage } from 'components/Container';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const NotFoundPage: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />

    <HeroContainer>
      <Section>
        <h1>
          Something <br />
          went wrong
        </h1>
        <p>
          The webpage you are trying to visit cannot be found. Refresh or try visiting our{' '}
          <CommunityLink to="/" title="Sharesight Community Forum">
            Community Forum
          </CommunityLink>{' '}
          for help.
        </p>

        <br />
        <br />

        <GatsbyLink asButton to="/" appearance="primary">
          Return to homepage
        </GatsbyLink>
      </Section>

      <SectionImage image={<HeroImage />} boxShadow={false} />
    </HeroContainer>
  </Layout>
);

export default NotFoundPage;
