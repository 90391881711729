const SVGHero = () => (
  <svg
    width="357"
    height="273"
    viewBox="0 0 357 273"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Not found</title>
    <rect
      x="241.774"
      y="55.3315"
      width="115.055"
      height="159"
      fill="url(#paint0_linear_2923_24639)"
    />
    <ellipse
      cx="299.301"
      cy="55.3324"
      rx="57.5274"
      ry="21.5245"
      fill="url(#paint1_linear_2923_24639)"
    />
    <ellipse
      cx="299.301"
      cy="214.199"
      rx="57.5274"
      ry="21.5245"
      fill="url(#paint2_linear_2923_24639)"
    />
    <rect
      x="34.1279"
      y="171.976"
      width="115.055"
      height="80.6099"
      fill="url(#paint3_linear_2923_24639)"
    />
    <ellipse cx="91.6553" cy="171.847" rx="57.5274" ry="21.5245" fill="#FFCA39" />
    <ellipse
      cx="91.6553"
      cy="251.475"
      rx="57.5274"
      ry="21.5245"
      fill="url(#paint4_linear_2923_24639)"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
      <ellipse cx="299.301" cy="52.8002" rx="18.6773" ry="4.1178" fill="#061392" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
      <ellipse cx="195.363" cy="228.981" rx="32.548" ry="7.17589" fill="#061392" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
      <ellipse cx="20.22" cy="266.632" rx="18.6773" ry="4.1178" fill="#061392" />
    </g>
    <circle cx="299.3" cy="19.4478" r="19.4478" fill="url(#paint5_radial_2923_24639)" />
    <circle cx="19.4478" cy="247.185" r="19.4478" fill="url(#paint6_radial_2923_24639)" />
    <circle cx="196.42" cy="104.395" r="19.4478" fill="url(#paint7_radial_2923_24639)" />
    <defs>
      <linearGradient
        id="paint0_linear_2923_24639"
        x1="391.306"
        y1="-15.2112"
        x2="153.916"
        y2="120.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2923_24639"
        x1="265.256"
        y1="76.8569"
        x2="309.741"
        y2="33.8079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808BF3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2923_24639"
        x1="391.306"
        y1="173.576"
        x2="333.737"
        y2="295.383"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2923_24639"
        x1="183.66"
        y1="136.212"
        x2="45.1779"
        y2="292.69"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2923_24639"
        x1="183.66"
        y1="210.852"
        x2="126.091"
        y2="332.658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2923_24639"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(311.909 19.4478) rotate(122.958) scale(23.1778)"
      >
        <stop stopColor="#98A1F2" />
        <stop offset="1" stopColor="#202EAE" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2923_24639"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.057 247.185) rotate(122.958) scale(23.1778)"
      >
        <stop stopColor="#98A1F2" />
        <stop offset="1" stopColor="#202EAE" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2923_24639"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(209.029 104.395) rotate(122.958) scale(23.1778)"
      >
        <stop stopColor="#FFD6BF" />
        <stop offset="1" stopColor="#FC6B1A" />
      </radialGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
